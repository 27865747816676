<template>
    <div 
        ref="tableWrapper" 
        class="tableWrapper flex-grow relative flex flex-col">
        <a-spin 
            class="table_loading_spin"
            :loading="tableLoading" />
        <ag-grid-vue
            class="ag-theme-alpine flex-grow opacity_transition"
            :class="tableLoading && 'table_loading'"
            :columnDefs="columnsVisible"
            :suppressColumnVirtualisation="true"
            :suppressRowVirtualisation="true"
            :overlayNoRowsTemplate="$t('table.no_rows')"
            :getRowStyle="getRowStyle"
            :rowSelection="rowSelection"
            suppressRowClickSelection
            :domLayout="domLayout"
            @grid-ready="onGridReady"
            @rowClicked="onRowClicked"
            @rowSelected="$emit('rowSelected', $event)"
            @selectionChanged="$emit('selectionChanged', $event)"
            :rowData="tableRows">
        </ag-grid-vue>        
        <div class="flex justify-end items-center mt-1">
            <div 
                v-if="tableInfo"
                class="mr-2">
                <a-modal
                    @cancel="settingsVisible = false"
                    :visible="settingsVisible"
                    :zIndex="settingZIndex"
                    :afterClose="afterClose">
                    <div class="columns_checkbox_group flex flex-col">
                        <div class="mb-2 font-semibold">
                            {{ $t('table.settings') }}
                        </div>
                        <div>
                            <div>
                                <div class="mb-2 flex justify-between font-medium">
                                    <span>{{ $t('table.columns') }}</span>
                                    <span>{{ $t('table.fixed') }}</span>
                                </div>
                                <a-checkbox 
                                    ref="columnsCheckbox"
                                    class="mb-1 pb-1 border-gray-300"
                                    :indeterminate="indeterminate" 
                                    :checked="checkAllColumns" 
                                    @change="onCheckAllChange">
                                    {{ $t('table.all') }}
                                </a-checkbox>
                            </div>
                        </div>
                        <a-checkbox-group 
                            v-model="columnsActive"
                            @change="onColumnCheckboxChange">
                            <a-row
                                v-for="column in columnOptions"
                                :key="column.value"
                                class="flex mt-1 first:mt-0">
                                <CheckboxColumn
                                    :tableName="model+pageName"
                                    :setColumnName="setColumnName"
                                    :tableColumns="tableColumns"
                                    :column="column" />
                            </a-row>
                        </a-checkbox-group>
                    </div>
                    <template #footer>
                        <div class="flex">
                            <a-button 
                                class="mr-2"
                                type="ui"
                                block
                                @click="dropColumns(); settingsVisible = false">
                                {{ $t('table.default') }}
                            </a-button>
                            <a-button 
                                block
                                type="primary"
                                @click="setColumns(); settingsVisible = false">
                                {{ $t('table.confirm') }}
                            </a-button>
                        </div>
                    </template>
                </a-modal>
            </div>
            <Pager
                v-if="showPager && hasData && tablePageSize && rowCount > tablePageSize"
                ref="Pager"
                :hash="hash"
                :changePage="changePage"
                :changeSize="changePageSize"
                :pageSize="tablePageSize"
                :page="page"
                :scrollElements="[
                    '.task_table .ant-table-body-inner',
                    '.task_table .ant-table-body'
                ]"
                :count="rowCount" />
        </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-balham.css"
import "ag-grid-community/styles/ag-theme-alpine.css"

import { AgGridVue } from "ag-grid-vue";
import CellRenderer from './CellRenderer.vue'
import CustomHeaderCell from "./CustomHeaderCell.vue";

import Pager from './Pager'
import CheckboxColumn from './CheckboxColumn.vue'

import eventBus from '@/utils/eventBus'
import { mapActions } from 'vuex'

import axios from 'axios'
import Vue from 'vue'
export default {
    components: {
        /* eslint-disable */ 
        agColumnHeader: CustomHeaderCell,
        CellRenderer,
         /* eslint-enable */ 
        CheckboxColumn,
        AgGridVue,
        Pager,
    },
    props: {

        /* ___ ОБЯЗАТЕЛЬНЫЕ ВХОДНЫЕ ПАРАМЕТРЫ ___ */

        /* Нужен для получения дефолтных настроек. Может быть:
            tasks,
            logistic,
            orders,
            chat_tasks,
            interests,
            projects,
            groups,
            meetings,
            sprints,
            analytics */ 
        tableType: {
            type: String,
            required: true
        },
        /* Связываясь с pageName образует ключ, 
            под которым настройки таблицы хранятся в сторе */
        model: {
            type: String,
            required: true
        },
        /* Связываясь с model образует ключ, 
            под которым настройки таблицы хранятся в сторе */
        pageName: {
            type: String,
            required: true
        },      
        endpoint: {
            type: String,
            default: null
        },
        autoHeight: {
            type: Boolean,
            default: false
        },
        /* ___ НЕ ОБЯЗАТЕЛЬНЫЕ ВХОДНЫЕ ПАРАМЕТРЫ ___ */
        /* Список данных для таблицы */
        // rowList: {
        //     type: Array,
        //     default: () => []
        // },  
        /* Функция открытия записи.
            Например, открытие задачи */
        openHandler: {
            type: Function,
            default: () => {}
        },
        showPager: {
            type: Boolean,
            default: true
        },

        taskType: { type: String, default: ''},
        main: {
            type: Boolean,
            default: false
        },
        getInvoicePayment: {
            type: Function,
            default: () => {}
        },
        showChildren: Boolean,
        reloadTask: {
            type: Function,
            default: () => {}
        },
        extendDrawer: {
            type:  Boolean,
            default: false
        },
        hash: {
            type:  Boolean,
            default: false
        },
        startEdit: {
            type: Function,
            default: () => {}
        },
        deleteSprint: {
            type: Function,
            default: () => {}
        },
        updateStatus: {
            type: Function,
            default: () => {}
        },
        openDescModal: {
            type: Function,
            default: () => {}
        },
        openModalStat: {
            type: Function,
            default: () => {}
        },
        takeTask: {
            type: Function,
            default: () => {}    
        },
        onRowClicked: { // Обработчик клика по строке таблицы
            type: Function,
            default: () => {}    
        },
        params: {
            type: Object,
            default: () => {}
        },
        organization: {
            type: Object,
            default: () => {}
        },
        hideActionColumn: {
            type: Boolean,
            default: false
        },
        useSelect: {
            type: Boolean,
            default: false
        },
        selectMode: {
            type: String,
            default: 'multiple'
        }
    },
    data() {
        return {
            axiosCancel: null,
            state: {},
            expandedRowKeys: [],
            settingsVisible: false,
            settingZIndex: 1000,
            indeterminate: false,
            checkAllColumns: false,
            columnsActive: [],
            gridApi: null,

            tableData: null,
            page: 1,
            tableLoading: false,
            overlayNoRowsTemplate: null
        }
    },
    computed: {
        // rowList() {
        //     return this.tableData?.results || []
        // },
        rowSelection() {
            if(this.useSelect) {
                return this.selectMode
            }
            return 'singleRow'
        },
        rowCount() {
            return this.tableData?.count || 0
        },
        domLayout() {
            return this.autoHeight ? 'autoHeight' : 'normal'
        },
        tableInfo() {
            return this.$store.state.table?.tablesInfo?.[this.model+this.pageName]
        },
        tableRows() {
            return this.$store.state.table?.tableRows?.[this.model+this.pageName] || []
        },
        tableColumns() {
            if(this.tableInfo?.columns?.length) {
                return this.tableInfo.columns
            }
            return []
        },
        tableRef() {
            const tableRef = `table_${this.tableType}_${this.model}_${this.pageName}`
            return tableRef
        },
        settingsColumn() {
            const titleName = 'titleWithSettings'
            const settingsColumn = this.tableColumns.find(column => column?.slots?.title === titleName)
            return settingsColumn?.defaultTitle || ''
        },
        hasData() { 
            return this.tableRows?.length
        },
        columnOptions() {
            const availableOptions = []
            this.tableColumns.forEach(column => { 
                column.hidable && availableOptions.push({
                    label: column.title,
                    value: column.dataIndex,
                    fixed: column.fixed
                })
            })
            return availableOptions
        },
        tableOrdering() {
            return this.tableInfo?.ordering || null
        },
        tablePageSize() {
            return this.tableInfo?.page_size || null
        },
        columnsVisible() {
            const columns = []
            // TODO: РЕФАКТОРИНГ ПОБОЧНОГО ЭФФЕКТА
            if(this.hideActionColumn) {
                const actionsColumn = this.tableColumns.find(column => column.dataIndex === 'actions')
                if (actionsColumn) {
                    actionsColumn.visible = false
                }
            }
            this.tableColumns.forEach(column =>  {
                column.visible && columns.push({
                    headerCheckboxSelection: column.headerCheckboxSelection,
                    checkboxSelection: column.checkboxSelection,
                    comparator: () => {},
                    field: column.dataIndex,
                    headerName: column.title || '',
                    cellRenderer: "CellRenderer",
                    width: column.width || null,
                    widget: column.cellRenderer,
                    resizable: true,
                    sortable: column.sorter, 
                    key: column.dataIndex,
                    pinned: column.fixed,
                    autoHeight: true,
                    wrapText: true,
                    headerComponentParams: {
                        ordering: this.tableInfo.ordering,
                        changeSort: this.changeSort
                    },

                    cellStyle: {
                        wordBreak: 'normal',
                        padding: '5px',
                        lineHeight: '1.4rem',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                    },
                    cellRendererParams: {
                        state: this.state,
                        changeState: this.changeState,
                        organization: this.organization,
                        tableType: this.tableType,
                        model: this.model,
                        pageName: this.pageName,
                        openHandler: this.openHandler,
                        taskType: this.taskType,
                        takeTask: this.takeTask,
                        startEdit: this.startEdit,
                        deleteSprint: this.deleteSprint,
                        updateStatus: this.updateStatus,
                        main: this.main,
                        expandedRowKeys: this.expandedRowKeys,
                        extendDrawer: this.extendDrawer,
                        reloadTask: this.reloadTask,
                        showChildren: this.showChildren,
                        expanded: this.expanded,
                        indent: this.indent,
                        getInvoicePayment: this.getInvoicePayment,
                        openModalStat: this.openModalStat,
                        openDescModal: this.openDescModal
                    }
                })
            }, [])
            return columns
        },
    },
    created() {
        this.overlayNoRowsTemplate = `<span style="padding: 10px;">${this.$t('table.no_rows')}</span>`

        this.getTableInfo({ 
            page_name: this.pageName, 
            model: this.model ,
            table_type: this.tableType
        })
            .then(() => {
                this.getTableData()
            })
    },
    mounted() {
        eventBus.$on(`open_table_settings_${this.pageName}`, zIndex => {
            this.settingZIndex = zIndex
            this.openSettingsModal()
        })
        eventBus.$on(`update_table_row_data`, () => {
            this.gridApi.setRowData(this.tableRows)
        })
        
        eventBus.$on(`update_filter_${this.model}`, () => {
            this.reloadTableData()
        })

        eventBus.$on(`table_row_${this.pageName}`, ({
            action,
            row=null,
            parentId=null,
        }) => {
            if(['update', 'delete', 'create'].includes(action)) {
                this.getTableData()
            } else if(action === 'expand') {
                const foundIndex = this.tableRows.findIndex(
                    tableRow => tableRow.id === parentId
                )
                row.forEach(item => item.parent_expand = parentId)
                if(foundIndex !== -1) {
                    this.tableRows.splice(foundIndex+1, 0, ...row)
                }
            } else if(action === 'collapse') {
                this.setTableData(this.tableRows.filter(
                    item => item.parent_expand !== parentId
                ))
            }
            

            action !== 'update' && this.gridApi.setRowData(this.tableRows)
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}`)
        eventBus.$off(`open_table_settings_${this.pageName}`)
        eventBus.$off(`update_table_row_data`)
        eventBus.$on(`table_row_${this.pageName}`)

    },
    methods: {
        ...mapActions({
            getTableInfo: 'table/getTableInfo',
            setTableInfo: 'table/setTableInfo'
        }),
        afterClose() {
            this.settingZIndex = 1000
        },
        getRowStyle(params) {
            if(params.data.parent_expand) {
                return {
                    background: '#f0f0f0'
                }
            }
        },
        changeState(value) {
            Vue.set(this, 'state', value)
        },        
        getRowId(params) {
            return params.data.id
        },
        reloadTableData() {
            this.changeState(null)
            this.changePage(1)
        },
        async getTableData() {
            if (!this.endpoint) {
                this.tableData = []
                this.$store.state.table.tableRows?.[this.model+this.pageName]?.splice(0)
                delete this.$store.state.table.tableRows[this.model+this.pageName]

                return;
            }
            if(this.axiosCancel) {
                this.axiosCancel.cancel()
                this.axiosCancel = null
            }
            const params = {
                page_name: this.pageName,
                page_size: this.tablePageSize,
                page: this.page,
                ordering: this.tableOrdering,
                ...this.params
            }
            this.tableLoading = true
            try {
                const axiosSource = axios.CancelToken.source()
                this.axiosCancel = axiosSource
                const { data } = await this.$http.get(this.endpoint, { 
                    cancelToken: this.axiosCancel.token,
                    params
                })
                this.tableData = data

                this.setTableData(data.results)
                
                
                // this.gridApi.sizeColumnsToFit()`
            } catch(error) {
                // this.$message.error(this.$t('table.getDataError'))
                console.error(error)
            } finally {
                this.tableLoading = false
            }  
        },
        setTableData(rows) {
            Vue.set(this.$store.state.table.tableRows, [this.model+this.pageName], rows)
        },
        onGridReady(params) {
            this.gridApi = params.api
            this.gridColumnApi = params.columnApi
        },
        onColumnCheckboxChange(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < this.columnOptions.length;
            this.checkAllColumns = checkedList.length === this.columnOptions.length;
        },
        onCheckAllChange({target}) {
            if(target.checked){
                this.tableColumns.forEach(column => 
                    column.hidable && this.columnsActive.push(column.dataIndex)
                )
            } else {
                this.columnsActive = []
            }

            this.indeterminate = false
            this.checkAllColumns = target.checked
        },
        setColumnName(columnValue, newName) {
            const column = this.tableColumns.find(column => column.dataIndex === columnValue)
            column.title = newName
        },
        async setColumns() {
            this.tableColumns.forEach((column, index) => {
                const isActive = this.columnsActive.includes(column.dataIndex)
                const isCustomizable = !!column.hidable
                if(isCustomizable)
                    if(isActive) 
                        this.tableColumns[index].visible = true
                    else 
                        this.tableColumns[index].visible = false
            })
            await this.sendTableInfo()
        },
        async sendTableInfo() {
            const defaultPageSize = 15
            const settings = { 
                columns: this.tableColumns,
                page_size: this.tablePageSize || defaultPageSize,
                ordering: this.tableOrdering
            }
            const params = {
                page_name: this.pageName,
                model: this.model,
                table_type: this.tableType,

                settings: settings 
            }
            try {
                await this.setTableInfo(params)
                await this.$http.post(`table_info/`, params)
            } catch (error) {
                console.error(error)
            }
        },
        columnsActiveInit() {
            this.columnsActive.splice(0)
            this.tableColumns.forEach(column => 
                (column.visible && column.hidable) && this.columnsActive.push(column.dataIndex)
            )

            this.indeterminate = !!this.columnsActive.length && this.columnsActive.length < this.columnOptions.length;
            this.checkAllColumns = this.columnsActive.length === this.columnOptions.length
        },
        async changePageSize(size) {
            this.tableInfo.page_size = size
            this.sendTableInfo()
            this.getTableData()
        },
        changePage(page) {
            this.page = page
            this.getTableData()
        },
        async changeSort(sortedColumn, sortDirection) {
            const columnIndex = this.tableColumns.findIndex(column => column.key === sortedColumn)
            this.tableColumns.forEach(column => { column.sortOrder && delete column.sortOrder })
            if(columnIndex !== -1) {
                this.tableColumns[columnIndex].sortOrder = sortDirection 
            }
            let ordering
            if(sortDirection) {
                if(sortDirection === 'desc') {
                    ordering = '-' + sortedColumn
                } else {
                    ordering = sortedColumn
                }
            } else {
                ordering = null
            }
            this.tableInfo.ordering = ordering
            
            this.changePage(1)
            this.sendTableInfo()
            
        },
        async dropColumns() {
            const tableName = {
                model: this.model,
                page_name: this.pageName,    
            }
            try {
                const { data } = await this.$http.post('/table_info/', {
                    ...tableName,
                    table_type: this.tableType,
                    drop: true,
                })
                const params = {
                    ...tableName,
                    settings: data 
                }
                this.setTableInfo(params)
                this.getTableData()
            } catch(error) {
                console.error(error)
            }
        },
        openSettingsModal() {
            this.settingsVisible = true
            this.columnsActiveInit()
        },
    },    
}
</script>

<style lang="scss" scoped>
.table_loading {
    opacity: 0.4;
}
.table_loading_spin {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
}
.tableWrapper::v-deep {
    .ag-root-wrapper{
        min-height: 25rem;
    }
}
.opacity_transition {
    transition: opacity 0.3s ease;
}
</style>

